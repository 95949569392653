//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState(['user'])
    },
    methods: {
        hide() {
            this.$store.commit('setShowBanner', false)
        }
    }
}

/* DO NOT EDIT OUTSIDE OF MASTER */
const axios = require('axios')
export default (context, inject) => {
    const api = {
        async request(obj, verify = false, surpress = false, loader = true) {
            obj.url = `${window.location.origin}/api`;
            if (process.env.REQUIRE_VERIFY && verify) {
                obj.headers.verify = await context.$recaptcha.execute("login");
            }
            if (process.env.API_ENV) {
                obj.headers.env = process.env.API_ENV;
            } else if (context.store.state.env) {
                obj.headers.env = context.store.state.env;
            }
            obj.headers.client_type = context.store.state.client;
            context.store.commit("setErrors", []);
            if (loader) {
                context.store.commit("setLoading", true);
            }
            return context
                .$axios(obj)
                .then((res) => {
                    context.store.commit("setLoading", false);
                    return res.data;
                })
                .catch((err) => {
                    context.store.commit("setLoading", false);
                    if (surpress === false) {
                        if (Array.isArray(err.response.data)) {
                            if (typeof err.response.data[0] === "string") {
                                context.store.commit(
                                    "setErrors",
                                    err.response.data
                                );
                            } else {
                                let errors = [];
                                err.response.data.map((e) => {
                                    errors.push(e.message);
                                });
                                context.store.commit("setErrors", errors);
                            }
                        } else if (err.response.data.message) {
                            if (
                                err.response.data.message ==
                                "Could not load shopping basket"
                            ) {
                                context.store.commit("setOrder", null);
                                context.store.commit("setErrors", [
                                    "There was an issue with your cart. Please start a new order.",
                                ]);
                                if (
                                    context.route.path.match(
                                        /\/order\/checkout/
                                    )
                                ) {
                                    context.store.commit(
                                        "setRedirect",
                                        "/order"
                                    );
                                }
                            } else {
                                context.store.commit("setErrors", [
                                    err.response.data.message,
                                ]);
                            }
                        } else {
                            context.store.commit("setErrors", [
                                "Connection issue. Please try again.",
                            ]);
                        }
                    }
                    return null;
                });
        },
        // Email subscription
        async subscribe(customer) {
            const subscription = await this.request({
                method: "post",
                headers: {
                    path: "eclub/subscribe",
                },
                data: customer,
            });
            return subscription;
        },
        // Users
        async createUser(customer) {
            const user = await this.request(
                {
                    method: "post",
                    headers: {
                        path: "members/create",
                    },
                    data: customer,
                },
                true
            );
            if (user) {
                context.store.commit("setToken", user.tokens);
            }
            return user;
        },
        async loginUser(creds) {
            const user = await this.request(
                {
                    method: "post",
                    headers: {
                        path: "members/signin",
                    },
                    data: creds,
                },
                true
            );
            if (user) {
                context.store.commit("setToken", user.tokens);
            }
            return user;
        },
        async loginSSO(creds) {
            let user = await this.request({
                method: "post",
                headers: {
                    path: "members/sso",
                },
                data: creds,
            });
            if (user) {
                context.store.commit("setToken", user.tokens);
            }
            return user;
        },
        async logoutUser() {
            await this.request({
                method: "delete",
                headers: {
                    path: "members/signout",
                    token: context.store.state.token.access.token,
                },
            });
            await this.abandonOrder();
            context.store.commit("setUser", null);
            context.store.commit("setToken", null);
            return true;
        },
        async getUser(withTokens = false) {
            let request = {
                method: "get",
                headers: {
                    path: "members/get",
                    token: context.store.state.token.access.token,
                },
            };
            if (context.store.state.token.id) {
                request.params = {
                    memberID: context.store.state.token.id,
                };
            }
            if (withTokens) {
                if (!request.params) {
                    request.params = {
                        withTokens: true,
                    };
                } else {
                    request.params.withTokens = true;
                }
            }
            const user = await this.request(request);
            if (user) {
                context.store.commit("setUser", user);
            }
            return user;
        },
        async updateUser(d) {
            const user = await this.request({
                method: "put",
                headers: {
                    path: "members/update",
                    token: context.store.state.token.access.token,
                },
                data: d,
            });
            if (user) {
                context.store.commit("setUser", d);
            }
            return user;
        },
        async setFaveLocation(id) {
            const fave = await this.request({
                method: "post",
                headers: {
                    path: `members/faves/locations/${id}`,
                    token: context.store.state.token.ordering.access.token,
                },
            });
            return fave;
        },
        async deleteFaveLocation(id) {
            const fave = await this.request({
                method: "delete",
                headers: {
                    path: `members/faves/locations/${id}`,
                    token: context.store.state.token.ordering.access.token,
                },
            });
            if (fave != null) {
                const user = JSON.parse(
                    JSON.stringify(context.store.state.user)
                );
                user.favelocations.map((f, i) => {
                    if (f.menu_id == id) {
                        user.favelocations.splice(i, 1);
                    }
                });
                context.store.commit("setUser", user);
            }
            return fave;
        },
        // Payments
        async getPayments() {
            const payments = await this.request({
                method: "get",
                headers: {
                    path: `members/payments`,
                    token: context.store.state.token.ordering.access.token,
                },
            });
            if (payments) {
                const user = JSON.parse(
                    JSON.stringify(context.store.state.user)
                );
                user.payments = payments.payments;
                context.store.commit("setUser", user);
            }
            return payments;
        },
        async deletePayment(pid) {
            const payments = await this.request({
                method: "delete",
                headers: {
                    path: `members/payments/${pid}`,
                    token: context.store.state.token.ordering.access.token,
                },
            });
            if (payments != null) {
                const user = JSON.parse(
                    JSON.stringify(context.store.state.user)
                );
                user.payments.map((p, i) => {
                    if (p.account.id == pid) {
                        user.payments.splice(i, 1);
                    }
                });
                context.store.commit("setUser", user);
            }
            return payments;
        },
        getPaymentForm() {
            return this.request({
                method: "post",
                headers: {
                    path: `payments/form`,
                },
                data: {
                    id: context.store.state.token.payments.access.id,
                },
            });
        },
        submitPayment(account) {
            return this.request({
                method: "post",
                headers: {
                    path: `payments/submit`,
                    token: context.store.state.token.ordering.access.token,
                    payment_token:
                        context.store.state.token.payments.access.token,
                },
                data: {
                    id: context.store.state.token.payments.access.id,
                    order_id: context.store.state.order.id,
                    store_id: context.store.state.order.menu_id,
                    amount: context.store.state.order.totals.total,
                    account,
                },
            });
        },
        // Addresses
        async getAddresses() {
            const addresses = await this.request({
                method: "get",
                headers: {
                    path: `members/addresses`,
                    token: context.store.state.token.ordering.access.token,
                },
            });
            if (addresses) {
                const user = JSON.parse(
                    JSON.stringify(context.store.state.user)
                );
                user.addresses = addresses.addresses;
                context.store.commit("setUser", user);
            }
        },
        async deleteAddress(aid) {
            const addresses = await this.request({
                method: "delete",
                headers: {
                    path: `members/addresses/${aid}`,
                    token: context.store.state.token.ordering.access.token,
                },
            });

            const user = JSON.parse(JSON.stringify(context.store.state.user));
            user.addresses.map((a, i) => {
                if (a.id == aid) {
                    user.addresses.splice(i, 1);
                }
            });
            context.store.commit("setUser", user);
            return addresses;
        },
        // Passwords
        async changePassword(newpassword, oldpassword) {
            return await this.request({
                method: "post",
                headers: {
                    path: "members/password",
                    token: context.store.state.token.access.token,
                },
                data: {
                    new: newpassword,
                    current: oldpassword,
                },
            });
        },
        async forgotPassword(email) {
            return await this.request(
                {
                    method: "post",
                    headers: {
                        path: "members/forgotpassword",
                    },
                    data: {
                        email,
                    },
                },
                true
            );
        },
        // Locations
        async findLocations(loc) {
            const locations = await this.request({
                method: "get",
                headers: {
                    path: "locations/search",
                },
                params: loc,
            });
            if (locations) {
                if (locations.count > 0) {
                    context.store.commit(
                        "setLocationList",
                        locations.locations
                    );
                } else {
                    context.store.commit("setLocationList", false);
                }
            } else {
                context.store.commit("setLocationList", null);
            }
            return locations;
        },
        async getLocation(id, byMenuId = false) {
            const request = {
                method: "get",
                headers: {
                    path: `locations/${id}`,
                },
            };
            if (byMenuId) {
                request.params = {
                    byMenuId: true,
                };
            }
            const location = await this.request(request);
            if (location) {
                context.store.commit("setLocation", location);
            }
            return location;
        },
        // Menus
        async getMenu(surpress = false, loader = true) {
            if (context.store.state.location.menu_id) {
                const menu = await this.request(
                    {
                        method: "get",
                        headers: {
                            path: `menus/${context.store.state.location.menu_id}`,
                        },
                    },
                    false,
                    surpress,
                    loader
                );
                if (menu) {
                    context.store.commit("setMenu", menu);
                    return true;
                } else {
                    return false;
                }
            } else {
                context.store.commit("setMenu", null);
                return false;
            }
        },
        getProduct(pid) {
            return this.request({
                method: "get",
                headers: {
                    path: `menus/${context.store.state.location.menu_id}/products/${pid}`,
                },
            });
        },
        // Order History
        async getRecentOrders() {
            const orders = await this.request({
                methods: "get",
                headers: {
                    path: "orders/recent",
                    token: context.store.state.token.ordering.access.token,
                },
            });
            if (orders) {
                context.store.commit("setOrderHistory", orders.orders);
            }
            return orders;
        },
        async createReorder(id) {
            const order = await this.request({
                method: "post",
                headers: {
                    path: "orders/reorder",
                    token: context.store.state.token.ordering.access.token,
                },
                data: {
                    id,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        // Favorite Orders
        async getFaveOrders() {
            const orders = await this.request({
                method: "get",
                headers: {
                    path: "orders/faves",
                    token: context.store.state.token.ordering.access.token,
                },
            });
            return orders;
        },
        async createFaveOrder(id, name) {
            const order = await this.request({
                method: "post",
                headers: {
                    path: "orders/faves",
                    token: context.store.state.token.ordering.access.token,
                },
                data: {
                    id,
                    description: name,
                },
            });
            return order;
        },
        async createFaveReorder(id) {
            const order = await this.request({
                method: "post",
                headers: {
                    path: "orders/faves/reorder",
                    token: context.store.state.token.ordering.access.token,
                },
                data: {
                    id,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        // Orders
        async createOrder() {
            let order = null;
            const headers = {
                path: "orders/create",
            };
            if (context.store.state.token) {
                headers.token = context.store.state.token.ordering.access.token;
            }
            order = await this.request({
                method: "post",
                headers,
                data: {
                    menu_id: context.store.state.location.menu_id,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        async abandonOrder() {
            if (context.store.state.order && context.store.state.token) {
                await this.request({
                    method: "delete",
                    headers: {
                        path: `orders/${context.store.state.order.id}/abandon`,
                        token: context.store.state.token.access.token,
                    },
                });
                context.store.commit("setOrder", null);
                return true;
            }
        },
        async transferOrder() {
            let order = await this.request({
                method: "post",
                headers: {
                    path: `orders/${context.store.state.order.id}/transfer`,
                },
                data: {
                    menu_id: context.store.state.location.menu_id,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        async retrieveOrder(oid) {
            const order = await this.request({
                method: "get",
                headers: {
                    path: `orders/${oid}`,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        async addToOrder(prods) {
            if (!context.store.state.order) {
                await this.createOrder(context.store.state.location.menu_id);
            }
            const order = await this.request(
                {
                    method: "post",
                    headers: {
                        path: `orders/${context.store.state.order.id}/products`,
                    },
                    data: {
                        products: prods,
                    },
                },
                false,
                false,
                false
            );
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        async updateInOrder(prods) {
            const order = await this.request(
                {
                    method: "put",
                    headers: {
                        path: `orders/${context.store.state.order.id}/products`,
                    },
                    data: {
                        products: prods,
                    },
                },
                true,
                false,
                false
            );
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        async removeFromOrder(pid) {
            const order = await this.request({
                method: "delete",
                headers: {
                    path: `orders/${context.store.state.order.id}/products/${pid}`,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        async validateOrder() {
            const totals = await this.request({
                method: "post",
                headers: {
                    path: `orders/${context.store.state.order.id}/validate`,
                },
            });
            if (totals) {
                const order = JSON.parse(
                    JSON.stringify(context.store.state.order)
                );
                order.taxes = totals.taxes;
                order.totals.tax = totals.totals.tax;
                order.totals.total = totals.totals.total;
                if (order.time.mode == "asap") {
                    order.time.earliest = totals.time;
                }
                context.store.commit("setOrder", order);
                context.store.commit("setSupports", totals.supports);
                totals.billing.map((b) => {
                    if (b.type == "creditcard" && b.accounts.length > 0) {
                        context.store.commit("setAccounts", b.accounts);
                        context.store.commit("setSavedPayments", true);
                        b.accounts.map((a) => {
                            if (a.account.default) {
                                context.store.commit(
                                    "setSelectedAccount",
                                    a.account.id
                                );
                            }
                        });
                    } else if (b.type == "creditcard") {
                        context.store.commit("setAccounts", []);
                        context.store.commit("setSavedPayments", false);
                    }
                });
            }
            return totals;
        },
        async setHandoff(mode) {
            const order = await this.request({
                method: "put",
                headers: {
                    path: `orders/${context.store.state.order.id}/handoff/${mode}`,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        async setAddress(address) {
            const type = context.store.state.location.services.dispatch
                ? "dispatch"
                : "delivery";
            const order = await this.request({
                method: "put",
                headers: {
                    path: `orders/${context.store.state.order.id}/address`,
                },
                data: {
                    type,
                    address,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        async setCustomFields(fields) {
            const order = await this.request({
                method: "put",
                headers: {
                    path: `orders/${context.store.state.order.id}/custom`,
                },
                data: {
                    fields,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        getCalendar(from, to) {
            return this.request({
                method: "get",
                headers: {
                    path: `menus/${context.store.state.location.menu_id}/times`,
                },
                params: {
                    from,
                    to,
                },
            });
        },
        async setTime(time) {
            const order = await this.request({
                method: "put",
                headers: {
                    path: `orders/${context.store.state.order.id}/time`,
                },
                data: {
                    time,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        async deleteTime() {
            const order = await this.request({
                method: "delete",
                headers: {
                    path: `orders/${context.store.state.order.id}/time`,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        async addTip(amount) {
            const order = await this.request({
                method: "put",
                headers: {
                    path: `orders/${context.store.state.order.id}/tip`,
                },
                data: {
                    amount,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        async addCoupon(code) {
            const order = await this.request({
                method: "put",
                headers: {
                    path: `orders/${context.store.state.order.id}/coupon`,
                },
                data: {
                    code,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        async removeCoupon() {
            const order = await this.request({
                method: "delete",
                headers: {
                    path: `orders/${context.store.state.order.id}/coupon`,
                },
            });
            if (order) {
                context.store.commit("setOrder", order);
            }
            return order;
        },
        getBasketToken() {
            const headers = {
                path: `orders/${context.store.state.order.id}/token`,
            };
            if (context.store.state.token) {
                headers.token = context.store.state.token.ordering.access.token;
            }
            return this.request({
                method: "post",
                headers,
            });
        },
        submitOrder(billing) {
            let headers = {
                path: `orders/${context.store.state.order.id}/submit`,
            };
            let data = {
                usertype: "guest",
                payments: billing,
            };
            if (context.store.state.token.ordering.access.token) {
                data.usertype = "user";
                headers.token = context.store.state.token.ordering.access.token;
            } else {
                data.first_name = context.store.customer.user.first_name;
                data.last_name = context.store.customer.user.last_name;
                data.email = context.store.customer.user.email;
                data.phone = context.store.customer.user.phone;
            }
            return this.request({
                method: "post",
                headers,
                data,
            });
        },
        trackConversion(cart, oid, products) {
            return this.request({
                method: "post",
                headers: {
                    path: `orders/${cart.id}/track`,
                },
                data: {
                    cart,
                    products,
                },
            });
        },
        trackPaymentFailure(oid, error) {
            return this.request({
                method: "post",
                headers: {
                    path: `orders/${oid}/track`,
                },
                data: {
                    error,
                    type: "ccsf",
                },
            });
        },
        getOrderStatus(id) {
            return this.request({
                method: "get",
                headers: {
                    path: `orders/${id}/status`,
                },
            });
        },
        cancelOrder(id) {
            return this.request({
                method: "delete",
                headers: {
                    path: `orders/${id}`,
                },
            });
        },
        addReferralToBasket(refferal) {
            console.log("refferal::::::::: ", refferal);

            return this.request({
                method: "put",
                headers: {
                    path: `orders/${context.store.state.order.id}/referrals`,
                },
                data: {
                    referrals: refferal,
                },
            });
        },
        async guestSMSOptIn(data) {
            let smsOptIn = {
                method: "post",
                url: `${process.env.TAPONIT_URL}/opt-in?keyword=join`,
                headers: {
                    "TOI-Auth": process.env.TAPONIT_KEY,
                },
                data: {
                    fullname: `${data.first_name} ${ data.last_name }`,
                    number: data.phone,
                    email: data.email,
                    marketing_channel: "Krystal Guest Web Form",
                },
            };

            // console.log(smsOptIn);

            await axios(smsOptIn).catch((err) => {
                console.log("tapon error", err.response.data);
            });
        },
        async guestEMAILOptIn(data) {
            // console.log('data::: ', data)
            let emailOptIn = {
                method: 'post',
                url: process.env.BIKKY_URL,
                headers: {
                  'X-Api-Key': process.env.BIKKY_KEY
                },
                data: {
                  source: 'Krystal Guest Web Form',
                  email: data.email,
                  first_name: data.first_name,
                  last_name: data.last_name,
                  phone: data.phone,
                  email_opt_in: data.emailUpdates || false,
                  sms_opt_in: data.optin_sms || false,
                }
            }
            
            // if (token.rd) {
            //     taponUpdate.data.externalIdentifiers.sparkfly = token.rd;
            // }

            // console.log(emailOptIn);

            await axios(emailOptIn).then(res => {
                console.log('emailOptIn::::: ', res.status)
              })
              .catch(err => {
                try {
                  console.log('emailOptIn:::::', err.response.status, err.response)
                } catch(e) {
                  console.error(err)
                }
              })
        },
    };

    inject("api", api);
    context.$api = api;
};
